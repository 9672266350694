<template>
  <div class="row align-items-center">
    <!-- Decrease -->
    <!-- <div class="col-12 text-center col-md-auto">
      <span class="count-minus" v-on:click="decreaseAmount()">
        <i class="fas fa-minus-square"></i>
      </span>
    </div> -->
    <!-- ./Decrease -->
    <div class="col-10">
      <div class="input-group input-group-sm" style="min-width:100px;">
        <input
          type="text"
          class="form-control"
          v-bind:value="value"
          v-on:input="onInput"
          ref="numberInput"
        />
        <div class="input-group-append" v-if="row.quantity_type">
          <span class="input-group-text">
            {{ row.quantity_type }}
          </span>
        </div>
      </div>
    </div>
    <!-- Increase -->
    <!-- <div class="col-12 text-center col-md-auto">
      <span class="count-plus" v-on:click.prevent="increaseAmount()">
        <i class="fas fa-plus-square"></i>
      </span>
    </div> -->
    <!-- ./Increase -->
  </div>
</template>
<script>
export default {
  name: "TableQuantity",
  data() {
    return {
      data: {
        current: this.value,
        max: this.max,
      },
    };
  },
  props: {
    max: {
      default: 1000,
    },
    value: {
      default: 1,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    componentId() {
      return (
        "wetable-content-row-cell-quantity-" +
        helper.getRandomInteger(1, 999999)
      );
    },
  },
  methods: {
    onInput(e) {
      if (e && e.target.value) {
        this.data.current = this.filterInput(e.target.value);
        this.triggerEvent();
      }
    },
    decreaseAmount() {
      if (this.data.current > 1) {
        this.data.current--;
        this.triggerEvent();
      }
    },
    increaseAmount() {
      if (this.data.current < this.max) {
        this.data.current++;
        this.triggerEvent();
      }
    },
    beforeTrigger() {
      if (this.data.current < this.max || this.data.current > 1) {
        this.triggerEvent();
      } else {
        this.data.current = 1;
      }
    },
    triggerEvent() {
      let data = {
        row: this.row,
        value: this.data.current,
      };
      this.$emit("input", this.data.current);
      this.$parent.$parent.$parent.$emit("qty-change", data);
    },
    filterInput(value) {
      const filtered = helper.numberInput(value);
      this.$refs.numberInput.value = filtered;
      return filtered;
    },
  },
};
</script>
